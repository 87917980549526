//Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { ImageService } from './image.service';
import { AccountService } from '../../account/account.service';
import { CsrService } from '../csr.service';

//Core
import { Image } from './Image';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  public title = 'Images!';
  public images: Image[];
  public image: Image;
  public userLogged: User;
  public issueId = 0;
  public imageEmpty = false;
  public showImage: boolean;
  public btnDeleteImage = false;
  public imageId = 0;
  public deleteModal = false;
  public progressSpinner = false;

  constructor(private readonly accountService: AccountService,
    private readonly imageService: ImageService,
    private readonly csrService: CsrService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getUser();

      this.issueId = this.route.snapshot.params['id'];

      this.image = {} as Image;

      this.getImagesFromCsr(this.issueId);
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public getImagesFromCsr(issueid: number): void {

    this.imageService.getImage(issueid)
      .subscribe((res: any) => this.images = res as Image[],
        (error: any) => {

          this.messageService.add({ key: 'imagekey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.images.length > 0) {
            this.imageEmpty = true;
          }
          else {
            this.imageEmpty = false;
          }

          const userRoles = this.userLogged.roles.find(row => row === 'Admin' || row === 'SuperAdmin');

          if (userRoles !== undefined) {
            this.btnDeleteImage = true;
          }
          else {
            this.btnDeleteImage = false;
          } 

        });
  }

  public fileChangeEvent(fileInput: any): void {
    //let files = <Array<File>>fileInput.target.files;
    this.onUploadImage(fileInput.target.files[0]);
  }

  public onUploadImage(file: any): void {

    if (this.isUserLoggedIn()) {

      this.progressSpinner = true;

      const formData = new FormData();

      formData.append(file.name, file);
      formData.append('issueid', this.issueId.toString());
      formData.append('userid', this.userLogged.id);

      this.imageService.uploadImage(formData)
        .subscribe((res: any) => {
          
          this.messageService.add({ key: 'imagekey', severity: 'success', summary: 'Image', detail: 'File upload successful' });          

          this.images = [];
          this.progressSpinner = false;
          this.checkAddNewAttachment(this.issueId, this.userLogged.id);
          //console.log('checkAddNewAttachment');
         //this.getImagesFromCsr(this.issueId);
        },
          (error: any) => {

            this.progressSpinner = false;
            this.messageService.add({ key: 'imagekey', severity: 'error', summary: 'Error', detail: error });

          });

    }
  }

  public checkAddNewAttachment(id: number, userId: string): void {

    this.csrService.checkAddNewAttachment(id, userId)
      .subscribe((res: any) => {
        //console.log(res);
        this.getImagesFromCsr(id);
      },
        (error: any) => {          
          console.log(error);
        });
  }

  public previewImage(image: Image): void {

    this.image = image;

    if (this.image.imageFileName === 'pdf.jpg') {
      this.downloadPdfFile(this.image.id);
    } else {
      this.showImage = true;
    }

  }

  public downloadPdfFile(id: number): void {

    this.imageService.downloadPdfFile(id)
      .subscribe((data: any) => {

        const nav = (window.navigator as any);

        if (nav && nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(data);
        } else {
          const objectUrl = URL.createObjectURL(data);
          window.open(objectUrl);
        }

        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //  window.navigator.msSaveOrOpenBlob(data);
        //} else {
        //  const objectUrl = URL.createObjectURL(data);
        //  window.open(objectUrl);
        //}
      },
        (error: any) => {

          this.messageService.add({ key: 'imagekey', severity: 'error', summary: 'Error', detail: error });

        });

  }

  public downloadImage(image: Image): void {

    if (image.fileType === 'file') {

      const nav = (window.navigator as any);

      if (nav && nav.msSaveOrOpenBlob) {
        // window.navigator.msSaveOrOpenBlob(image.pathFile);
      } else {
        const fileURL = image.pathFile;
        window.open(fileURL);
      }

      ////var url = 'data:application/pdf;base64,' + encodeURI(image.fileName);
      //// window.open(url, '_blank', 'height=650,width=840,resizable=1');
    }
    else {
      const fileURL = image.pathImagePreview;
      window.open(fileURL);
    }
  }

  public showDeleteConf(id: number): void {

    this.imageId = id;
    this.deleteModal = true;
  }

  public deleteImage(): void {

    this.deleteModal = false;

    this.imageService.deleteImage(this.imageId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'imagekey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.images = [];
        this.getImagesFromCsr(this.issueId);

      },
        (error: any) => {

          this.messageService.add({ key: 'imagekey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUser(): User {

    return this.accountService.getLoggedInUser();
  }

}
