//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { CsrService } from '../csr.service';
import { AccountService } from '../../account/account.service';
import { CommentsAssignedToMyService } from '../../csr/comments-assigned-to-my.service';
import { DepartmentService } from '../../csr/department/department.service';
import { IssueTypeService } from '../../csr/issue-type/issue-type.service';
import { ProjectService } from '../../project/project.service';

//Core
import { Csr } from '../csr';
import { Comment } from '../comment/comment';
import { Department } from '../../csr/department/department';
import { IssueType } from '../../csr/issue-type/issue-type';
import { Project } from '../../project/project';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';

interface SelectItemModel {
  name: string,
  code: string
}

@Component({
  selector: 'app-csr-edit',
  templateUrl: './csr-edit.component.html'
})
export class CsrEditComponent implements OnInit {

  public title = 'Edit CSR: ';
  public issue: Csr = {} as Csr;
  public issueId = 0;
  public projects: Project[];
  public project: Project = {} as Project;;
  public department: Department = {} as Department;;
  public departments: Department[] = [];
  public assignedto: User = {} as User;
  public selectPriority: SelectItemModel;
  public priorities: SelectItemModel[] = [];
  public selectStatus: SelectItemModel;
  public statuslist: SelectItemModel[] = [];
  public users: User[];
  public ProgramTitle = 'Program';
  public issuetypes: IssueType[] = [];
  public selectIssuetypes: IssueType;
  public Sending = false;
  public additionalData: string[] = [];
  public showAdditionalData = false;
  public showForm = false;
  public commentsAssignedToMy: Comment[] = [];
  public inprogressdateValidator = false;
  public completeddateValidator = false;
  @ViewChild('issueform', { static: false }) public issueForm: NgForm;
  public processIcon = false;

  constructor(private readonly csrService: CsrService,
    private readonly accountService: AccountService,
    private readonly projectService: ProjectService,
    private readonly departmentService: DepartmentService,
    private readonly issueTypeService: IssueTypeService,
    private readonly commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {

    this.statuslist = [
      { name: 'New request', code: 'New request' },
      { name: 'In Progress', code: 'In Progress' },
      { name: 'Completed', code: 'Completed' },
      { name: 'Waiting for additional information', code: 'Waiting for additional information' },
      { name: 'Special projects', code: 'Special projects' },
      { name: 'Not Completed', code: 'Not Completed' },
      { name: 'Rejected', code: 'Rejected' },
      { name: 'Holding Administrative Clearance', code: 'Holding Administrative Clearance' },
      { name: 'Security events log', code: 'Security events log' }
    ];

    this.priorities = [
      { name: 'Low', code: 'Low' },
      { name: 'Medium', code: 'Medium' },
      { name: 'High', code: 'High' }
    ];
  }

  public ngOnInit(): void {    

    if (this.isUserLoggedIn()) {
      const id = this.route.snapshot.params['id'];
      this.getCsr(id);
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public getCsr(id: number): void {

    this.csrService.getCsr(id)
      .subscribe({
        next: data => {

        this.issue = data as Csr;        
      },
       error: error => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {

          this.getProjects(this.issue.departmentId);
          this.getUsers();
          this.getDepartments();

          this.selectPriority = this.priorities.filter((x) => x.code === this.issue.priority)[0];
          this.selectStatus = this.statuslist.filter((x) => x.code === this.issue.status)[0];

          // Para que no de error el primeng p-calendar.
          if (this.issue.dateSubmitted !== null) {
            this.issue.dateSubmitted = new Date(this.issue.dateSubmitted.toString());
          }
          if (this.issue.inProgressDate !== null) {
            this.issue.inProgressDate = new Date(this.issue.inProgressDate.toString());
          }
          if (this.issue.completedDate !== null) {
            this.issue.completedDate = new Date(this.issue.completedDate.toString());
          }
          if (this.issue.scheduleDate !== null) {
            this.issue.scheduleDate = new Date(this.issue.scheduleDate.toString());
          } else {
            this.issue.scheduleDate = new Date();
          }
        }
      });
  }  

  public getProjects(departmentId: number): void {

    this.projectService.getProjectsPerDepartment(departmentId)
      .subscribe({
        next: data => {
          this.projects = data as Project[];          
        },
        error: error => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {
          this.project = this.projects.filter((x) => x.id === this.issue.projectId)[0];
        }
      });
  }

  public getUsers(): void {

    this.processIcon = true;

    this.accountService.getShortUsersList()
      .subscribe({
        next: data => {
          this.users = data as User[];         
        },
        error: error => {
          this.processIcon = false;
          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {
          this.processIcon = false;
          const assigned = this.users.filter((x) => x.id === this.issue.assignedTo);
          if (assigned !== undefined) {
            this.assignedto = { id: assigned[0].id, name: assigned[0].name } as User;
          }
        }
      });
  }

  public getDepartments(): void {

    this.departmentService.getDepartments()
      .subscribe({
        next: data => {

          this.departments = data as Department[];          
        },
        error: error => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {

          this.department = this.departments.filter((x) => x.id === this.issue.departmentId)[0];

          this.getIssueTypesPerDepartment();

          if (this.department.name === 'LWH Software/Hardware') {
            this.ProgramTitle = 'Program';
          }
          else if (this.department.name === 'Laboratory Instrumentation') {
            this.ProgramTitle = 'Instrument';
          }
          else if (this.department.name === 'Building Facility & Maintenance') {
            this.ProgramTitle = 'Location';
          }
          else if (this.department.name === 'Phone and Outbound Networks') {
            this.ProgramTitle = 'Location';
          }
          else if (this.department.name === 'Reagents Issues') {
            this.ProgramTitle = 'Department';
          }
          else {
            this.ProgramTitle = 'other department';
          }
        }
      });
  }

  public getIssueTypesPerDepartment(): void {

    this.issueTypeService.getIssueTypesPerDepartment(this.department.id)
      .subscribe({
        next: data => {

        this.issuetypes = data as IssueType[];        
      },
        error: error => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {

          this.selectIssuetypes = this.issuetypes.filter((x) => x.description === this.issue.issueType)[0];
        }
      });
  }

  public onSubmit(): void {
    
    if (this.isUserLoggedIn()) {

      if (this.issue.inProgressDate === null) {
        this.issue.inProgressDate = new Date('2000, 1, 1');
      } else {
        this.issue.strInProgressDate = `${this.issue.inProgressDate.toLocaleDateString('en-US')} ${this.issue.inProgressDate.toLocaleTimeString('en-US')}`; //this.issue.inProgressDate.toLocaleString();
      }

      if (this.issue.completedDate === null) {
        this.issue.completedDate = new Date('2000, 1, 1');
      } else {
        this.issue.strCompletedDate = `${this.issue.completedDate.toLocaleDateString('en-US')} ${this.issue.completedDate.toLocaleTimeString('en-US')}`; //this.issue.completedDate.toLocaleString();
      }

      if (this.issue.scheduleDate === null) {
        this.issue.scheduleDate = new Date('2000, 1, 1');
      } else {
        this.issue.strScheduleDate = `${this.issue.scheduleDate.toLocaleDateString('en-US')} ${this.issue.scheduleDate.toLocaleTimeString('en-US')}`; //this.issue.completedDate.toLocaleString();
      }

      if (this.additionalData.length > 0) {
        this.issue.additionalData = this.additionalData;
      }

      this.issue.projectId = this.project.id;
      this.issue.project = this.project;
      if (this.assignedto !== undefined) {
        this.issue.assignedTo = this.assignedto.id.toString();
      }
      this.issue.priority = this.selectPriority.code;
      this.issue.issueType = this.selectIssuetypes.description;
      this.issue.status = this.selectStatus.code;
      this.issue.departmentId = this.department.id;

      this.sendingEMail(true);

      this.csrService.editCsr(this.issue)
        .subscribe({
          next: data => {

            this.issueId = data as number;            
          },
          error: error => {

            this.sendingEMail(false);
            this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
          },
          complete: () => {

            this.sendingEMail(false);
            this.getComments();
            this.router.navigate([`/csr/csr-details/${this.issueId}`]);
          }
        });
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public sendingEMail(value: boolean): void {

    if (value === true) {
      this.Sending = true;
    }
    else {
      this.Sending = false;
    }
  }

  public showAdditionalDataMissing(): void {
    this.showForm = false;
    this.showAdditionalData = true;
    window.scrollTo(0, document.body.scrollHeight);
  }
    
  public getComments(): void {

    this.csrService.getCommentsAssignedToMy()
      .subscribe({
        next: (data: any) => {

          this.commentsAssignedToMy = data as Comment[];
        },
        error: error => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        },
        complete: () => {

          this.commentsAssignedToMyService.updateComments(this.commentsAssignedToMy);
        }
      });
  }

  public handleChange(event: any): void {

    if (event.value.code === 'Waiting for additional information') {

      this.clearValidators();

      if (this.issue.inProgressDate === null) {
        this.issue.inProgressDate = new Date();     
      }

      this.showForm = true;

    } else if (event.value.code === 'Completed') {

      if (this.issue.inProgressDate === null) {
        this.inprogressdateValidator = true;
        this.issueForm.form.get('inprogressdate')!.setValidators([Validators.required]);
        this.issueForm.form.get('inprogressdate')!.updateValueAndValidity();
      }

      if (this.issue.completedDate === null) {
        this.completeddateValidator = true;
        this.issueForm.form.get('completeddate')!.setValidators([Validators.required]);
        this.issueForm.form.get('completeddate')!.updateValueAndValidity();
      }

      if (this.issue.resolution === null) {
        this.issueForm.form.get('resolution')!.setValidators([Validators.required]);
        this.issueForm.form.get('resolution')!.updateValueAndValidity();
      }

    } else if (event.value.code === 'In Progress') {

      if (this.issue.inProgressDate === null) {
        this.inprogressdateValidator = true;
        this.issueForm.form.get('inprogressdate')!.setValidators([Validators.required]);
        this.issueForm.form.get('inprogressdate')!.updateValueAndValidity();
      }

      if (this.issue.completedDate === null) {
        this.completeddateValidator = false;
        this.issueForm.form.get('completeddate')!.clearValidators();
        this.issueForm.form.get('completeddate')!.updateValueAndValidity();
      }

      if (this.issue.resolution === null) {
        this.issueForm.form.get('resolution')!.clearValidators();
        this.issueForm.form.get('resolution')!.updateValueAndValidity();
      }

    } else {

      this.clearValidators();
    }
  }

  public clearValidators(): void {

    if (this.issue.inProgressDate === null) {
      this.inprogressdateValidator = false;
      this.issueForm.form.get('inprogressdate')!.clearValidators();
      this.issueForm.form.get('inprogressdate')!.updateValueAndValidity();
    }

    if (this.issue.completedDate === null) {
      this.completeddateValidator = false;
      this.issueForm.form.get('completeddate')!.clearValidators();
      this.issueForm.form.get('completeddate')!.updateValueAndValidity();
    }

    if (this.issue.resolution === null) {
      this.issueForm.form.get('resolution')!.clearValidators();
      this.issueForm.form.get('resolution')!.updateValueAndValidity();
    }
  }

  public onSelectInProgressDate(): void {

    //const dateTime = new Date();
    //this.issue.inProgressDate = dateTime;
    this.inprogressdateValidator = false;
  }

  public onSelectCompletedDate(): void {

    //const dateTime = new Date();
    //this.issue.completedDate = dateTime;
    this.completeddateValidator = false;
  }

  public handleChangeDepartment(event: any): void {

    this.getProjects(event.value.id);  

    if (this.department.name === 'LWH Software/Hardware') {
      this.ProgramTitle = 'Program';
    }
    else if (this.department.name === 'Laboratory Instrumentation') {
      this.ProgramTitle = 'Instrument';
    }
    else if (this.department.name === 'Building Facility & Maintenance') {
      this.ProgramTitle = 'Location';
    }
    else if (this.department.name === 'Phone and Outbound Networks') {
      this.ProgramTitle = 'Location';
    }
    else if (this.department.name === 'Reagents Issues') {
      this.ProgramTitle = 'Department';
    }
    else {
      this.ProgramTitle = 'other department';
    }

    this.getIssueTypesPerDepartment();
  }

  public assignedToMy(): void {

    const userLogged = this.getUserLogged();

    const assignedtomy = this.users.filter((x) => x.id === userLogged.id);

    if (assignedtomy !== undefined) {
      this.assignedto = assignedtomy[0];
    }
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUserLogged(): User {

    return this.accountService.getLoggedInUser();
  }

}
