import { Injectable } from '@angular/core';
//import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

//Model
import { Comment } from '../csr/comment/comment';

@Injectable()
export class CommentsAssignedToMyService {

  public comments = new BehaviorSubject<Comment[]>([] as Comment[]);
  public scast = this.comments.asObservable();

  constructor() { }

  public updateComments(newComments: Comment[]): void {

    this.comments.next(newComments);
  }

}
