import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AppComponent } from '../app.component';

//Services
import { CsrService } from '../csr/csr.service';

//Core
import { GlobalCsrData } from '../csr/global-csr-data';

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightMenuComponent {

  public globalCsrData: GlobalCsrData[] = [];

  constructor(public layoutService: LayoutService, public app: AppComponent, private readonly csrService: CsrService) {
    this.getGlobalCsrData();
  }

    get rightMenuActive(): boolean {
        return this.layoutService.state.rightMenuActive;
    }

    set rightMenuActive(_val: boolean) {
        this.layoutService.state.rightMenuActive = _val;
    }

  public getGlobalCsrData(): void {

    this.csrService.getGlobalCsrData()
      .subscribe((res: any) => {
        this.globalCsrData = res as GlobalCsrData[];
      },
        (error: any) => {
          console.log(error);
        });
  }

}
