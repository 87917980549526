//Angular
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { CsrService } from '../../csr/csr.service';
import { CommentService } from './comment.service';
import { CommentsAssignedToMyService } from '../../csr/comments-assigned-to-my.service';
import { AccountService } from '../../account/account.service';

//Core
import { Csr } from '../../csr/csr';
import { Comment } from './comment';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';

/* tslint:disable:no-import-side-effect ordered-imports */
//images
//import '../../../assets/enable_open_mail.png';
//import '../../../assets/enable_close_mail.png';
//import '../../../assets/unable_open_mail.png';
//import '../../../assets/unable_close_mail.png';
/* tslint:enable:no-import-side-effect */

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  styles: []
})
export class CommentComponent implements OnInit {

  public issue: Csr;
  public comments: Comment[] = [];
  public commentsAssignedToMy: Comment[] = [];
  public comment: Comment;
  public userLogged: User;
  public issueId = 0;
  public commentEmpty = false;
  public Sending = false;
  public btnDeleteComment = false;
  public deleteModal = false;
  public commentId = 0;
  public disableWritingComments = false;

  constructor(private readonly accountService: AccountService,
    private readonly commentService: CommentService,
    private readonly commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly csrService: CsrService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getUser();
      this.issueId = this.route.snapshot.params['id'];
      const dt = new Date();
      this.comment = { id: 0, issueId: this.issueId, commentText: '', commentDate: dt.toLocaleString(), userId: this.userLogged.id, user: this.userLogged, isRead: false } as Comment;
      this.getIssue();
    }
    else {

      this.router.navigate(['/account/login']);

    }
  }

  public getIssue(): void {

    this.csrService.getCsr(this.issueId)
      .subscribe((res: any) => this.issue = res as Csr,
        (error: any) => {

          this.messageService.add({ key: 'csrdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          if (this.issue.status === 'Rejected') {
            this.disableWritingComments = true;
          }
          this.getCommentsFromCsr(this.issueId);
        });
  }

  public getCommentsFromCsr(issueid: number): void {

    this.commentService.getComments(issueid)
      .subscribe((res: any) => this.comments = res as Comment[],
        (error: any) => {

          this.messageService.add({ key: 'commentkey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {

          if (this.comments.length > 0) {
            this.commentEmpty = true;
          }
          else {
            this.commentEmpty = false;
          }

          const userRoles = this.userLogged.roles.find(row => row === 'Admin' || row === 'SuperAdmin');

          if (userRoles !== undefined) {
            this.btnDeleteComment = true;
          }
          else {
            this.btnDeleteComment = false;
          } 

        });
  }

  public onSubmit(form: NgForm): void {

    if (this.isUserLoggedIn()) {

      this.sendingEMail(true);

      this.commentService.addComment(this.comment)
        .subscribe((res: any) => this.issueId = res as number,
          (error: any) => {

            this.sendingEMail(false);
            this.messageService.add({ key: 'commentkey', severity: 'error', summary: 'Error', detail: error });
          },
          () => {

            this.sendingEMail(false);
            this.messageService.add({ key: 'commentkey', severity: 'success', summary: 'Comment', detail: 'Comment added successfully' });
            
            this.comments = [];
            this.getCommentsFromCsr(this.comment.issueId);
            form.resetForm();
            this.getComments();
          });
    }
    else {

      this.router.navigate(['/account/login']);
    }
  }

  public sendingEMail(value: boolean): void {

    if (value === true) {
      this.Sending = true;
    }
    else {
      this.Sending = false;
    }
  }

  public handleChange(comment: Comment): void {

    if (comment.isRead === true) {
      comment.imagePathCloseMail = '/assets/unable-close-mail.png';
      comment.imagePathOpenMail = '/assets/enable-open-mail.png';
    }
    else {
      comment.imagePathCloseMail = '/assets/enable-close-mail.png';
      comment.imagePathOpenMail = '/assets/unable-open-mail.png';
    }

    this.commentService.editComment(comment)
      .subscribe((res: any) => this.comment = res as Comment,
        (error: any) => {

          this.messageService.add({ key: 'commentkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          this.messageService.add({ key: 'commentkey', severity: 'success', summary: 'Comment', detail: 'Comment updated successfully' });  
          const dt = new Date();
          this.comment = { id: 0, issueId: this.issueId, commentText: '', commentDate: dt.toLocaleString(), userId: this.userLogged.id, user: this.userLogged, isRead: false } as Comment;
          this.getComments();
          this.getCommentsFromCsr(this.issueId);
        });
  }

  public getComments(): void {

    this.csrService.getCommentsAssignedToMy()
      .subscribe((res: any) => {

        this.commentsAssignedToMy = res as Comment[];

        this.commentsAssignedToMyService.updateComments(this.commentsAssignedToMy);
      },
        (error: any) => {

          this.messageService.add({ key: 'commentkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public showDeleteConf(id: number): void {

    this.commentId = id;
    this.deleteModal = true;
  }

  public deleteComment(): void {

    this.deleteModal = false;

    this.commentService.deleteComment(this.commentId.toString())
      .subscribe((res: any) => {

        this.messageService.add({ key: 'commentkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

      },
        (error: any) => {

          this.messageService.add({ key: 'commentkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {
          
          this.comments = [];
          this.getCommentsFromCsr(this.issueId);

        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUser(): User {

    return this.accountService.getLoggedInUser();
  }

}
