import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './account/auth.guard';

//PrimeNg Ultima
import { AppLayoutComponent } from './layout/app.layout.component';

//Components
import { HomeComponent } from './home/home.component';
import { AppNotfoundComponent } from './app-not-found/app.notfound.component';

//account
import { LoginComponent } from './account/login/login.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { RegisterComponent } from './account/register/register.component';
import { UserNotAuthorizedComponent } from './account/user-not-authorized/user-not-authorized.component';
import { UserComponent } from './account/users/user.component';
import { UsersComponent } from './account/users/users.component';

//csr
import { CsrComponent } from './csr/csr.component';
import { DepartmentComponent } from './csr/department/department.component';
import { MyCsrComponent } from './csr/my-csr/my-csr.component';
import { CsrDetailsComponent } from './csr/csr-details/csr-details.component';
import { DashboardComponent } from './csr/dashboard/dashboard.component';
import { QuickSearchComponent } from './csr/quick-search/quick-search.component';
import { CsrAddComponent } from './csr/csr-add/csr-add.component';
import { CsrEditComponent } from './csr/csr-edit/csr-edit.component';
import { IssueTypeComponent } from './csr/issue-type/issue-type.component';

//guide
import { GuideAddComponent } from './guides/guide-add/guide-add.component';
import { GuidesPerProjectComponent } from './guides/guides-per-project/guides-per-project.component';
import { GuideGroupByProjectsComponent } from './guides/guide-group-by-projects/guide-group-by-projects.component';
import { GuideComponent } from './guides/guide.component';
import { VideoComponent } from './guides/video/video.component';

//Project
import { ProjectDetailsComponent } from './project/project-details/project-details.component';
import { ProjectComponent } from './project/project.component';
import { ProjectsComponent } from './project/projects/projects.component';

//Client
import { ClientDetailsComponent } from './client/client-details/client-details.component';
import { ClientComponent } from './client/client.component';
import { ClientsComponent } from './client/clients/clients.component';

//CompensatoryTime
import { CompensatoryTimeComponent } from './compensatory-time/compensatory-time.component';
import { UserCompensatoryTimeComponent } from './compensatory-time/user-compensatory-time/user-compensatory-time.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        //path: '', component: AppMainComponent,
        path: '', component: AppLayoutComponent,
        children: [
          { path: '', component: HomeComponent },
          { path: 'account/login', component: LoginComponent },
          { path: 'account/change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
          { path: 'account/register', component: RegisterComponent, canActivate: [AuthGuard] },
          { path: 'account/user-not-authorized', component: UserNotAuthorizedComponent, canActivate: [AuthGuard] },
          { path: 'account/users/:filterComments', component: UsersComponent, canActivate: [AuthGuard] },
          { path: 'account/user/:id', component: UserComponent, canActivate: [AuthGuard] },
          { path: 'csr/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
          { path: 'csr/department/:id', component: DepartmentComponent, canActivate: [AuthGuard] },
          { path: 'csr/my-csr', component: MyCsrComponent, canActivate: [AuthGuard] },
          { path: 'csr/csr-details/:id', component: CsrDetailsComponent, canActivate: [AuthGuard] },
          { path: 'csr/quick-search/:status/:filterComments', component: QuickSearchComponent, canActivate: [AuthGuard] },
          { path: 'csr/csr-add/:id/:departmentId', component: CsrAddComponent, canActivate: [AuthGuard] },
          { path: 'csr/csr-edit/:id', component: CsrEditComponent, canActivate: [AuthGuard] },
          { path: 'csr/issue-type/:filterComments', component: IssueTypeComponent, canActivate: [AuthGuard] },
          { path: 'guide/guide-add/:filterComments', component: GuideAddComponent, canActivate: [AuthGuard] },
          { path: 'guide/guide-group-by-projects', component: GuideGroupByProjectsComponent, canActivate: [AuthGuard] },
          { path: 'guide/guide-details/:projectid', component: GuidesPerProjectComponent, canActivate: [AuthGuard] },
          { path: 'project/projects/:filterComments', component: ProjectsComponent, canActivate: [AuthGuard] },
          { path: 'project/project-details/:id', component: ProjectDetailsComponent, canActivate: [AuthGuard] },
          { path: 'client/clients/:filterComments', component: ClientsComponent, canActivate: [AuthGuard] },
          { path: 'client/client-details/:id', component: ClientDetailsComponent, canActivate: [AuthGuard] },
          { path: 'guide/video/:id', component: VideoComponent, canActivate: [AuthGuard] },
          { path: 'compensatory-time/user-compensatory-time', component: UserCompensatoryTimeComponent, canActivate: [AuthGuard] }          
        ]
      },
      { path: 'notfound', component: AppNotfoundComponent },
      { path: '**', redirectTo: '/notfound' },   
    ], { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }



//import { NgModule } from '@angular/core';
//import { ExtraOptions, RouterModule, Routes } from '@angular/router';
//import { AppLayoutComponent } from './layout/app.layout.component';

//const routerOptions: ExtraOptions = {
//  anchorScrolling: 'enabled'
//};

//const routes: Routes = [
//  {
//    path: '', component: AppLayoutComponent,
//    children: [
//      { path: '', loadChildren: () => import('./demo/components/dashboards/dashboards.module').then(m => m.DashboardsModule) },
//      { path: 'uikit', data: { breadcrumb: 'UI Kit' }, loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
//      { path: 'utilities', data: { breadcrumb: 'Utilities' }, loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
//      { path: 'pages', data: { breadcrumb: 'Pages' }, loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) },
//      { path: 'profile', data: { breadcrumb: 'User Management' }, loadChildren: () => import('./demo/components/profile/profile.module').then(m => m.ProfileModule) },
//      { path: 'documentation', data: { breadcrumb: 'Documentation' }, loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
//      { path: 'blocks', data: { breadcrumb: 'Prime Blocks' }, loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
//      { path: 'ecommerce', data: { breadcrumb: 'E-Commerce' }, loadChildren: () => import('./demo/components/ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
//      { path: 'apps', data: { breadcrumb: 'Apps' }, loadChildren: () => import('./demo/components/apps/apps.module').then(m => m.AppsModule) }
//    ]
//  },
//  { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
//  { path: 'wizard', data: { breadcrumb: 'Wizard' }, loadChildren: () => import('./demo/components/pages/wizard/wizard.module').then(m => m.WizardModule) },
//  { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
//  { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },
//  { path: 'notfound2', loadChildren: () => import('./demo/components/notfound2/notfound2.module').then(m => m.Notfound2Module) },
//  { path: '**', redirectTo: '/notfound' }
//];

//@NgModule({
//  imports: [RouterModule.forRoot(routes, routerOptions)],
//  exports: [RouterModule]
//})
//export class AppRoutingModule { }
