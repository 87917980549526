import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[] = [];

  public selectNumber = {
    cero: 0,
    one: 1,
    two: 2,
    three: 3
  };

    ngOnInit() {
      this.model = [

        { label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
        { label: 'New CSR', icon: 'pi pi-fw pi-plus', routerLink: ['/csr/department', this.selectNumber.two] },
        { label: 'Security Events', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/csr/department', this.selectNumber.three] },
        { label: 'My CSR', icon: 'pi pi-fw pi-briefcase', routerLink: ['/csr/my-csr'] },
        { label: 'Guides & Videos', icon: 'pi pi-fw pi-fw pi-info-circle', routerLink: ['/guide/guide-group-by-projects'] },
        {
          label: 'Admin', icon: 'pi pi-fw pi-cog', routerLink: ['/csr/department', this.selectNumber.one],
          items: [
            { label: 'Dashboard', icon: 'pi pi-fw pi-microsoft', routerLink: ['/csr/department', this.selectNumber.one] },
            {
              label: 'Configurations', icon: 'pi pi-fw pi-cog',
              items: [
                { label: 'Projects', icon: 'pi pi-fw pi-cog', routerLink: ['/project/projects/0'] },
                { label: 'Clients', icon: 'pi pi-fw pi-cog', routerLink: ['/client/clients/0'] },
                { label: 'Add Guides & Videos', icon: 'pi pi-fw pi-cog', routerLink: ['/guide/guide-add/0'] },
                { label: 'Issue Type', icon: 'pi pi-fw pi-cog', routerLink: ['/csr/issue-type/0'] },
              ]
            },
            { label: 'Register', icon: 'pi pi-fw pi-user-plus', routerLink: ['/account/register'] },
            { label: 'Users', icon: 'pi pi-fw pi-users', routerLink: ['/account/users/0'] },
            { label: 'Compensatory Time', icon: 'pi pi-fw pi-clock', routerLink: ['/compensatory-time/user-compensatory-time'] },
          ]
        }
      ];
   
    }
}
