import {Component} from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

  constructor(public layoutService: LayoutService) { }

  public currentYear: number = new Date().getFullYear();
  public AppVersion = '3.2.2';

  public goToFaceBook() {
    window.open('https://www.facebook.com/laboratorioclinicoirizarryguasch', '_blank');
  }

  public goToTwitter() {
    window.open('https://twitter.com/lcigpr', '_blank');
  }

  public goToGoogle() {
    window.open('https://plus.google.com/+Milaboratoriopr', '_blank');
  }

}
