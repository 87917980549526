import { Component } from '@angular/core';

//Service
import { FileService } from '../shared/service/file.service';

import { saveAs } from 'file-saver';

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  styles: []
})

export class HomeComponent {

  public message: string;

  constructor(private fileService: FileService) {
    this.message = 'Customer Service Request';
  }

  public downloadTeanviewerLWH_App(): void {
    this.fileService.downloadTeanviewerLWH_App().subscribe((blob: any) => {
      const newBlob = new Blob([blob], { type: 'application/octet-stream' });
      saveAs(newBlob, 'downloadTeanviewerLWH_App.zip');
    });
  }

}
