// Angular
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module.ts';
import { RouterModule } from '@angular/router';

// Modules
import { SharedModule } from './shared/shared.module';
import { AccountModule } from './account/account.module';
import { CsrModule } from './csr/csr.module';
import { GuideModule } from './guides/guide.module';
import { ProjectModule } from './project/project.module';
import { ClientModule } from './client/client.module';
import { CompensatoryTimeModule } from './compensatory-time/compensatory-time.module';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

// Application Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { VideoComponent } from './guides/video/video.component';

// PrimeNg Components
//import { AppCodeModule } from './menu/app.code.component';
//import { AppMainComponent } from './menu/app.main.component';
//import { AppMenuComponent } from './menu/app.menu.component';
//import { AppMenuitemComponent } from './menu/app.menuitem.component';
//import { AppInlineProfileComponent } from './menu/app.profile.component';
//import { AppBreadcrumbComponent } from './menu/app.breadcrumb.component';
//import { AppConfigComponent } from './menu/app.config.component';
//import { AppRightpanelComponent } from './menu/app.rightpanel.component';
//import { AppTopbarComponent } from './menu/app.topbar.component';
//import { AppFooterComponent } from './menu/app.footer.component';

//import { MenuService } from './menu/app.menu.service';

// Application Services
import { AuthInterceptor } from './account/auth.interceptor';
import { HttpErrorInterceptor } from './core/http-error-interceptor';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    //AppCodeModule,
    AccountModule,
    CsrModule,
    GuideModule,
    ProjectModule,
    ClientModule,
    CompensatoryTimeModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    //AppMainComponent,
    //AppMenuComponent,
    //AppMenuitemComponent,
    //AppInlineProfileComponent,
    //AppTopbarComponent,
    //AppFooterComponent,
    //AppRightpanelComponent,
    //AppConfigComponent,
    //AppBreadcrumbComponent,
    VideoComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    //MenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
