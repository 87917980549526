//Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { CsrService } from '../csr.service';
import { AccountService } from '../../account/account.service';
import { CommentsAssignedToMyService } from '../../csr/comments-assigned-to-my.service';

//Core
//import { Csr } from '../csr';
import { MyCsr } from './my-csr';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-my-csr',
  templateUrl: './my-csr.component.html',
  styleUrls: ['./my-csr.component.scss'],
  styles: []
})
export class MyCsrComponent implements OnInit {

  public title = 'My CSR';
  public userLogged: User;
  public loading: boolean;
  public csrs: MyCsr[] = [];
  public cols: any[];
  public commentEmpty = false;
  public commentsQty = 0;
  public legendStatusCSR = false;

  constructor(private readonly csrService: CsrService,
    private readonly accountService: AccountService,
    private readonly commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      // User mode ********
      this.userLogged = this.getUser();
      this.getMyCMR(this.userLogged.id);
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public getMyCMR(userid: string): void {

    this.title = 'My CSR';
    this.loading = true;

    this.csrService.getMyCsrs(userid)
      .subscribe((res: any) => {
        this.csrs = res as MyCsr[];
        this.loadColumns();
        this.loading = false;
        this.checkCommentsByService();
      },
        (error: any) => {

          this.messageService.add({ key: 'mycsrkey', severity: 'error', summary: 'Error', detail: error });
                    
        });
  }

  public selectCsr(issueid: number): void {

    this.router.navigate([`/csr/csr-details/${issueid}`]);
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'CSR #' },
      { field: 'projectName', header: 'Project' },
      { field: 'subject', header: 'Subject' },
      { field: 'clientIssueBy', header: 'Client' },
      { field: 'assignedTo', header: 'Assigned To' },
      { field: 'department', header: 'Department' }
    ];
  }

  public checkCommentsByService(): void {

    this.commentsAssignedToMyService.scast.subscribe(comments => {

      if (comments.length > 0) {
        this.commentEmpty = true;
        this.commentsQty = comments.length;
      }
      else {
        this.commentEmpty = false;
        this.commentsQty = 0;
      }
    });

  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUser(): User {

    return this.accountService.getLoggedInUser();
  }  
}
