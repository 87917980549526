//Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { GuideService } from '../guide.service';
import { AccountService } from '../../account/account.service';

//Core
import { Guide } from '../guide';
import { User } from '../../account/model/user';

//Primeng
import { MessageService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-guides-per-project',
  templateUrl: './guides-per-project.component.html'
})
export class GuidesPerProjectComponent implements OnInit {

  public title = 'Guides';
  public guides: Guide[];
  public guide: Guide;
  public showImage: boolean;
  public btnDeleteGuide = false;
  public cols: any[];
  public deleteModal = false;
  public guideId = 0;
  public projectId = 0;
  public items: MenuItem[];
  public loading = false;

  constructor(private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly guideService: GuideService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn() === true) {

      this.guide = {} as Guide;

      this.route.params.subscribe(
        (params: any) => {

          this.projectId = +params['projectid'];

          this.getGuides(this.projectId);

        });

      const user = this.getUser();

      const filterMenu = this.items.filter(a => a.label === 'guide-details');

      if (filterMenu.length === 0) {

        const newItem = { label: 'guide-details', routerLink: `/guide/guide-details/${this.projectId}` } as MenuItem;

        this.items.push(newItem);
      }
    }
    else {

      this.router.navigate(['/account/login']);
    }
  }

  public getGuides(projectid: number): void {

    this.guideService.getGuides(projectid)
      .subscribe(res => {

        this.guides = res as Guide[];
        this.title = `Guides: [ ${this.guides[0].projectName} (guides: ${this.guides.length.toString()}) ]`;
        this.loadColumns();
      },
        (error: any) => {

          this.messageService.add({ key: 'guideperprojectkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public showVideo(Id: Number): void {

    this.router.navigate(['guide/video', Id]);   
  }

  public previewImage(guide: Guide): void {

    this.guide = guide;

    if (guide.imageFileName === 'pdf.jpg') {
      this.downloadPdfFile(guide.id);
    } else {
      this.showImage = true;
    }
  }

  public selectGuide(guide: Guide): void {

    const fileURL = guide.strFile;
    window.open(fileURL);
  }

  public downloadPdfFile(id: number): void {

    this.guideService.downloadPdfFile(id)
      .subscribe((data: any) => {

        const nav = (window.navigator as any);

        if (nav && nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(data);
        } else {
          const objectUrl = URL.createObjectURL(data);
          window.open(objectUrl);
        }

      },
        (error: any) => {

          this.messageService.add({ key: 'guideperprojectkey', severity: 'error', summary: 'Error', detail: error });

        });

  }

  public loadColumns(): void {

    this.cols = [
      { field: 'guideTitle', header: 'Title' },
      { field: 'currentVersion', header: 'Version' }
    ];
  }

  public showDeleteConf(id: number, projectid: number): void {

    this.guideId = id;
    this.projectId = projectid;
    this.deleteModal = true;
  }

  public deleteGuide(): void {

    this.deleteModal = false;

    this.guideService.deleteGuide(this.guideId.toString())
      .subscribe((res: any) => {

        this.messageService.add({ key: 'guideperprojectkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });
        this.getGuides(this.projectId);
      },
        (error: any) => {

          this.messageService.add({ key: 'guideperprojectkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public getUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    const userRoles = userLogged.roles.find(row => row === 'Admin' || row === 'SuperAdmin');
    if (userRoles !== undefined) {
      this.btnDeleteGuide = true;
    }
    else {
      this.btnDeleteGuide = false;
    }   

    return userLogged;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
