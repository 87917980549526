//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { AccountService } from '../account.service';

//Core
import { Users } from '../model/users';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})

export class UsersComponent implements OnInit {

  public title = 'Users list';
  public users: Users[];
  public cols: any[];
  public loading: boolean;

  constructor(private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
  }

  public ngOnInit(): void {
    this.getAllUsers();
  }

  public getAllUsers(): void {

    this.accountService.getAllUsers()
      .subscribe(res => {
        this.users = res as Users[];

        this.users.forEach(row => {
          row.clientname = row.client.clientname.toString();
        });

        this.loadColumns();

        this.loading = false;
      },
        error => {

          this.messageService.add({ key: 'userskey', severity: 'error', summary: 'Error', detail: error });

          if (error === 'Unauthorized') {
            this.router.navigate(['/account/login']);
          }

        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'userName', header: 'Username' },
      { field: 'email', header: 'Email' },
      { field: 'clientname', header: 'Client' },
      { field: 'phoneNumber', header: 'Phone' }
    ];

    this.loading = true;
  }

}
